import { NgModule } from '@angular/core';

import { BasketIdStreamService } from '@ts/basket/shared/data-access';
import { BasketIdFixedAsStreamService } from '@ts/basket/shared/data-access-staff';
import { CategoryFormFeatureFieldModule } from '@ts/category/form/feature-field';
import { FacilityFormFeatureFieldModule } from '@ts/facility/form/feature-field';
import { FacilityZoneFormFeatureFieldModule } from '@ts/facility/zone/form/feature-field';
import { FinanceCashFlowLaneFormFeatureFieldModule } from '@ts/finance/cash-flow/lane/form/feature-field';
import { FinanceCashStorageFormFeatureFieldModule } from '@ts/finance/cash-storage/form/feature-field';
import { FinanceLiabilityFormFeatureFieldModule } from '@ts/finance/liability/form/feature-field';
import { OrderFilterUiStatusFormModule } from '@ts/order/filter/ui-status-form';
import { PricePurchaseFormFeatureFieldModule } from '@ts/price/purchase/form/feature-field';
import { PricePurchaseRecordFormFeatureFieldModule } from '@ts/price/purchase/record/form/feature-field';
import { PriceSellFormFeatureFieldModule } from '@ts/price/sell/form/feature-field';
import { ProductFormFeatureFieldModule } from '@ts/product/form/feature-field';
import { ProductFormUiFieldModule } from '@ts/product/form/ui-field';
import { ProductRangeFormFeatureFieldModule } from '@ts/product/range/form/feature-field';
import { PromoActionUiFormCommonModule } from '@ts/promo/action/ui-form-common';
import { PromoBenefitUiFormModule } from '@ts/promo/benefit/ui-form';
import { PromoConditionUiFormModule } from '@ts/promo/condition/ui-form';
import { PromoFormFeatureStaffFieldModule } from '@ts/promo/form/feature-staff-field';
import { PromoUsageUiFormModule } from '@ts/promo/usage/ui-form';
import { PurchasingGroupFormFeatureFieldModule } from '@ts/purchasing/group/form/feature-field';
import { PurchasingSupplierPurchasingMetaFormFeatureFieldModule } from '@ts/purchasing/supplier-purchasing-meta/form/feature-field';
import { ReceivingFormFeatureFieldModule } from '@ts/receiving/form/feature-field';
import { ReturnToSupplierFormFeatureFieldModule } from '@ts/return-to-supplier/form/feature-field';
import {
  getAppImports,
  getAppProviders,
} from '@ts/shared/feature-apps-bootstrap';
import { SharedFormFieldUiButtonSelectModule } from '@ts/shared/form/field/ui-button-select';
import { SharedFormUiImageModule } from '@ts/shared/form/ui-image';
import { SharedFormUiRepeatModule } from '@ts/shared/form/ui-repeat';
import { SharedFormUiTextEditorModule } from '@ts/shared/form/ui-text-editor';
import { SharedMoneyFormUiFieldModule } from '@ts/shared/money/form/ui-field';
import { SupplierFormFeatureFieldModule } from '@ts/supplier/form/feature-field';
import { TransportFormFeatureFieldModule } from '@ts/transport/form/feature-field';
import { TransportLaneFormFeatureFieldModule } from '@ts/transport/lane/form/feature-field';
import { UserSharedFormFeatureFieldModule } from '@ts/user/shared/form/feature-field';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    ...getAppImports(),
    CategoryFormFeatureFieldModule,
    // =================================
    // Facility Form Field
    // =================================
    FacilityFormFeatureFieldModule,
    FacilityZoneFormFeatureFieldModule,
    // =================================
    // Cash Flow Lane Form Field
    // =================================
    FinanceCashFlowLaneFormFeatureFieldModule,
    // =================================
    // Cash Storage Form Field
    // =================================
    FinanceCashStorageFormFeatureFieldModule,
    FinanceLiabilityFormFeatureFieldModule,
    SharedMoneyFormUiFieldModule,
    SharedFormUiImageModule,
    SharedFormUiTextEditorModule,
    SharedFormUiRepeatModule,
    SharedFormFieldUiButtonSelectModule,
    // =================================
    // Price Purchase Change Form Field
    // =================================
    PricePurchaseFormFeatureFieldModule,
    // =================================
    // Price Purchase Record Form Field
    // =================================
    PricePurchaseRecordFormFeatureFieldModule,
    // =================================
    // Price Sell Form Field
    // =================================
    PriceSellFormFeatureFieldModule,
    // =================================
    // Purchasing Supplier Purchasing Meta Form Field
    // =================================
    PurchasingSupplierPurchasingMetaFormFeatureFieldModule,
    ProductFormUiFieldModule,
    ProductRangeFormFeatureFieldModule,
    // =================================
    // Receiving Form Field
    // =================================
    ReceivingFormFeatureFieldModule,
    // =================================
    // Return to Supplier Form Field
    // =================================
    ReturnToSupplierFormFeatureFieldModule,
    // =================================
    // User Form Field
    // =================================
    UserSharedFormFeatureFieldModule,
    // =================================
    // Supplier Form Field
    // =================================
    SupplierFormFeatureFieldModule,
    // =================================
    // PROMO FORMLY MODULES
    // =================================
    PromoActionUiFormCommonModule,
    PromoBenefitUiFormModule,
    PromoConditionUiFormModule,
    PromoUsageUiFormModule,
    // =================================
    // PURCHASING FORMLY MODULES
    // =================================
    PurchasingGroupFormFeatureFieldModule,
    OrderFilterUiStatusFormModule,
    ProductFormFeatureFieldModule,
    PromoFormFeatureStaffFieldModule,
    // =================================
    // Transport Lane Form Field
    // =================================
    TransportLaneFormFeatureFieldModule,
    // =================================
    // Transport Form Field
    // =================================
    TransportFormFeatureFieldModule,
  ],
  providers: [
    ...getAppProviders(),
    // In the staff site, we want to be able to edit another user's basket.
    // To facilitate this, // instead of using user's basket id,
    // we sometimes override it with another user's basket id.
    { provide: BasketIdStreamService, useClass: BasketIdFixedAsStreamService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
